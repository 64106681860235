<template>
  <Navbar :fullClubList="fullClubList" :categoryClick="catClick"/>
  <router-view :fullClubList="fullClubList" @categoryClick="changeCatClick"/>
  <Footer />
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: 'App',
  components: {Navbar, Footer},
  data(){
    return{
      fullClubList: [],
      catClick: '',
    }
  },
  created() {
    fetch('/api/clubs')
      .then(res => res.json())
      .then(data => this.fullClubList = this.shuffleArray(data));

    this.$router.beforeEach((to, from, next) => {
      //useful info here : console.log(to); console.log(from);
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      next();
    });
  },
  methods:{
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    },
    changeCatClick(c){
      if(c == this.catClick){
        this.catClick = '_RefreshCategory_';
        setTimeout(() => this.catClick = c, 50);
      }
      else
        this.catClick = c;
    }
  }
}
</script>

<style>
:root {
  --primary-color: #4285F4;
  --primary-color-light: #5C9FFF; 
  --white-gray: #F0F2F4;
  --lightest-gray: #D0D0D0;
  --light-gray: #777777;
  --dark-gray: #3a3d4a;
  --dark-gray-two: #2e2e2e;
  --light-black: #171717;
  --orange-accent: #ee6c4d;
  --navbar-height: 60px;
  --mobile-width: 600px;
  --medium-width: 1200px;
}
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  font-family: 'Open Sans', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body{
  margin: 0;
}

</style>
