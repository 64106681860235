<template>
  <div class="full-footer">
    <div class="logo-wrapper">
      <router-link to="/" class="logo">
        bruinclubs
      </router-link> 
    </div>
    <div class="footer-pagelinks">
      <router-link to="/rankings" class="footer-link">
        Rankings
      </router-link>
      <router-link to="/contact" class="footer-link">
        Contact
      </router-link>
      <router-link to="/prizes" class="footer-link">
        Prizes
      </router-link>
      <router-link to="/verify" class="footer-link">
        Verify my Club
      </router-link>
    </div>
  </div>
</template>

<style scoped>
.full-footer{
  background: #7dadfb;
}
.logo-wrapper{
  padding-top: 50px;
}
.logo{
  font-size: 28px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  text-decoration: none;
  color: white;
  transition: .2s all;
}
.logo:hover{
  color: #DDDDDD;
}
.footer-pagelinks{
  margin-top: 30px;
  padding-bottom: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.footer-link{
  text-decoration: none;
  transition: .2s all;
  color: #DDDDDD;
  margin: 15px 0;
  width: fit-content;
}
.footer-link:hover{
  color: white;
}
</style>