<template>
  <div class="navbar">
    <router-link to="/" class="navbar-home">
      bruinclubs
    </router-link>
    <div class="search-bar" id="full-search-bar">
      <button class="category-selector" id="category-selector-button">
        {{curCategory.length ? curCategory : 'All'}}<i class="fas fa-sort-down"></i>
      </button>
      <input v-model="searchText" type="text" class="search-input" id="navbar-search-input" @keyup="updateSuggestionPanel" @click="updateSuggestionPanel" spellcheck="false" @keyup.enter="enterSearch" autocomplete="off"/>
      <i class="fas fa-search" @click="enterSearch"></i>

      <div class="suggestions-panel" v-if="showSuggestions" id="suggestions-panel" :style="{left: catButtonWidth + 'px'}">
        <div v-for="club in curClubList" :key="club['Club Name']" class="suggestion" @click="suggestionClick(club['Club Name'])">
          <!--{{this.highlightSeachText(club['Club Name'])}}-->
          <span v-html="this.highlightSearchText(club['Club Name'])" />
          <i  v-if="club['Verified']" class="fas fa-check-circle"></i>
        </div>
      </div>
      <div class="categories-panel" v-if="showCategories">
        <div v-for="category in allClubCategories" :key="category" class="category" @click="setCategory(category)">
          {{category}}
        </div>
      </div>
    </div> 
    <div class="background-cover" id="background-cover"></div>
  </div>
</template>

<script>
export default {
  props: ['fullClubList', 'categoryClick'],
  data(){
    return{
      allClubCategories: [],
      curClubList: [],

      searchText: "",
      curCategory: "",

      showSuggestions: false,
      showCategories: false,
      catButtonWidth: 46,
    }
  },
  mounted(){
    document.addEventListener("mousedown", this.changeSearchFocus);
    setTimeout(() =>this.catButtonWidth = document.getElementById('category-selector-button').offsetWidth, 150);
  },
  watch: {
    fullClubList(){
      if(this.fullClubList.length)
        this.fillClubCategories();
    },
    categoryClick(c){
      if(c == '_RefreshCategory_')
        return;
      this.setCategory(c);
      this.searchText = '';
      this.$router.push({
        path: '/clubs/_CatSearch_/' + this.curCategory
      });
    }
  },  
  methods: {
    changeSearchFocus(e){
      if(e.target.id == 'navbar-search-input'){
        document.getElementById('background-cover').style.transition = '.1s all';
        document.getElementById('background-cover').style.opacity = '0';
        document.getElementById('background-cover').style.display = 'block';
        document.getElementById('background-cover').style.transition = '0.5s all';
        setTimeout(() => document.getElementById('background-cover').style.opacity = '0.4', 100);
        document.getElementById('full-search-bar').style.border = '2px solid var(--primary-color)';
        document.getElementById('category-selector-button').style.border = 'none';
        document.getElementById('category-selector-button').style.borderRight = '1px solid lightgray';
        this.showCategories = false;
        this.updateSuggestionPanel();
      }
      else if(e.target.id == 'category-selector-button'){
        document.getElementById('background-cover').style.display = 'none';
        document.getElementById('full-search-bar').style.border = '1px solid gray';
        document.getElementById('category-selector-button').style.border = '2px solid var(--primary-color)';
        this.showCategories = !this.showCategories;
        this.showSuggestions = false;
      }
      else if(e.target.classList[0] == 'suggestion' || e.target.classList[0] == 'category'){
        document.getElementById('background-cover').style.display = 'none';
        document.getElementById('full-search-bar').style.border = '1px solid gray';
        document.getElementById('category-selector-button').style.border = 'none';
        document.getElementById('category-selector-button').style.borderRight = '1px solid lightgray';
      }
      else{
        document.getElementById('background-cover').style.display = 'none';
        document.getElementById('full-search-bar').style.border = '1px solid gray';
        document.getElementById('category-selector-button').style.border = 'none';
        document.getElementById('category-selector-button').style.borderRight = '1px solid lightgray';
        this.showSuggestions = false;
        if (e.target.classList[0] != 'categories-panel')
        this.showCategories = false;
      }
    },
    fillClubCategories(){
      this.fullClubList.forEach(element => {
        let categories = element['Category'].split(', ');
        for(let c of categories){
          if(!this.allClubCategories.includes(c))
            this.allClubCategories.push(c);
        }
      });
      this.allClubCategories.sort();
      this.allClubCategories.unshift('All');
    },
    updateSuggestionPanel(){
      this.curClubList = this.fullClubList.filter(a => a['Category'].split(', ').some(cat => cat == this.curCategory || !this.curCategory)).sort((a,b) => {
        let aS = a['Club Name'].toLowerCase().indexOf(this.searchText.toLowerCase()), bS = b['Club Name'].toLowerCase().indexOf(this.searchText.toLowerCase());
        return (aS == -1 ? (bS == -1 ? 0 : 1) : (bS == -1 ? -1 : aS - bS));
      }).splice(0,10)
      .filter(a => a['Club Name'].toLowerCase().includes(this.searchText.toLowerCase()))
      this.showSuggestions = this.curClubList.length > 0 && (this.searchText.length > 0 || this.curCategory.length);
    },
    highlightSearchText(s){
      let i = s.toLowerCase().indexOf(this.searchText.toLowerCase());
      return s.substring(0,i) + '<span style="color: #888888; font-weight: 300">' + s.substring(i,i+this.searchText.length) + '</span>' + s.substring(i + this.searchText.length);
    },
    suggestionClick(clubName){
      this.searchText = clubName;
      this.showSuggestions = false;
      this.showCategories = false;
      this.$router.push({
        path: '/clubs/Cname='+this.searchText+'/'+this.curCategory
      })
    },
    setCategory(cat){
      this.showCategories = false;
      if(cat == 'All')
        this.curCategory = '';
      else
        this.curCategory = cat;
      setTimeout(() =>this.catButtonWidth = document.getElementById('category-selector-button').offsetWidth, 150);
    },
    enterSearch(){
      document.getElementById('background-cover').style.display = 'none';
      this.showSuggestions = false;
      this.showCategories = false;
      this.$router.push({
        path: '/clubs/'+(this.searchText ? this.searchText : '_CatSearch_')+(this.curCategory ? '/' : '')+this.curCategory
      })
    }
  }
}
</script>

<style scoped>
.navbar{
  display: flex;
  flex-direction: column;
  background: white;
  height: var(--navbar-height);
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 30px rgb(0 0 0 / 20%);
  z-index: 25;
  position: relative;
}
.navbar-home{
  font-size: 28px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  position: absolute;
  left: 30px;
  text-decoration: none;
  color: var(--primary-color);
  transition: .2s all;
}
.navbar-home:hover{
  color: var(--primary-color-light);
}
.search-bar{
  position:relative;
  height: 40px;
  display: flex;
  border: 1px solid gray;
  border-radius: 4px 6px 6px 4px;
  z-index:50;
}
.category-selector{
  height: 40px;
  border: none;
  border-right: 1px solid lightgray;
  border-radius: 4px 0 0 4px;
  cursor: pointer
}
.category-selector:hover{
  background: #D0D0D0;
}
.fa-sort-down{
  margin-left: 10px;
  margin-top: -2px;
}
.search-input{
  height: 23px;
  max-width: 600px;
  width: 50vw;
  padding: 9px 10px 8px 0;
  text-indent: 8px;
  border: none;
  font-size: 16px;
}
.search-input:focus{
  outline: none;
}
.fa-search{
  background: var(--primary-color);
  color: white;
  padding: 10px;
  border-radius: 0 4px 4px 0;
  font-size: 18px;
  cursor: pointer;
}
.fa-search:hover{
  background: var(--primary-color-light);
}
.suggestions-panel{
  position: absolute;
  background: white;
  top: 41px;
  left: 46px;
  text-align: left;
  border: 1px solid black;
  max-width: 600px;
  width: calc(50vw + 10px);
  border-radius: 0 0 4px 4px;
}
.suggestion{
  cursor: pointer;
  padding: 10px 6px;
  font-size: 16px;
  font-weight: 600;
}
.suggestion:hover{
  background:#E0E0E0;
}
.suggestion span{
  /*Deals with click on span not registering as a click on the suggestion*/
  pointer-events: none;
}
.categories-panel{
  position: absolute;
  background: white;
  top: 40px;
  left: 0;
  text-align: left;
  border: 1px solid black;
  overflow-y: scroll;
  height: 40vh;
  width: auto;
}
.category{
  cursor: pointer;
  padding: 10px 6px;
  font-size: 14px;
}
.category:hover{
  background:#E0E0E0;
}
.background-cover{
  position:fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /*width: 100vw;
  height: 100vh;*/
  opacity: .3;
  background: black;
  z-index: 25;
  display:none;
  transition: .5s all;
}

@media screen and (max-width: 960px) {
  .navbar{
    height: 100px;
  }
  .navbar-home{
    position: initial;
    margin-bottom: 10px;
  }
}
.fa-check-circle{
  margin-left: 5px;
  color: var(--primary-color);
}
</style>