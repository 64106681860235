import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/pages/main/Home.vue"),
    meta: {
      title: "bruinclubs",
      metaTags: [
        {
          name: "description",
          content:
            "Find and rate any club at UCLA",
        },
        {
          property: "og:description",
          content:
            "Find and rate any club at UCLA",
        },
      ],
    },
  },
  {
    path: "/clubs/:search?/:category*",
    name: "Clubs",
    component: () => import("@/pages/main/Clubs.vue"),
    props: true,
    meta: {
      title: "bruinclubs",
      metaTags: [
        {
          name: "description",
          content:
            "View all of the 1442 clubs that UCLA has to offer!",
        },
        {
          property: "og:description",
          content:
            "View all of the 1442 clubs that UCLA has to offer!",
        },
      ],
    },
  },
  {
    path: "/clubpages/:clubId",
    name: "ClubPage",
    component: () => import("@/pages/main/ClubPage.vue"),
    props: true,
    meta: {
      title: "bruinclubs club page",
      metaTags: [
        {
          name: "description",
          content: "Find info or leave a review for this club",
        },
        {
          property: "og:description",
          content: "Find info or leave a review for this club",
        },
      ],
    },
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("@/pages/contact/Contact.vue"),
    props: false,
    meta: {
      title: "bruinclubs contact page",
      metaTags: [
        {
          name: "description",
          content: "Contact bruinclubs for bugs, feedback, issues, and concerns",
        },
        {
          property: "og:description",
          content: "Contact bruinclubs for bugs, feedback, issues, and concerns",
        },
      ],
    },
  },
  {
    path: "/verify",
    name: "verify",
    component: () => import("@/pages/main/Verify.vue"),
    props: false,
    meta: {
      title: "bruinclubs verify your club",
      metaTags: [
        {
          name: "description",
          content: "Verify your club to display a certified check mark, professional email address, and updated club info",
        },
        {
          property: "og:description",
          content: "Verify your club to display a certified check mark, professional email address, and updated club info",
        },
      ],
    },
  },
  {
    path: "/verifysignup",
    name: "verifysignup",
    component: () => import("@/pages/contact/VerifySignUp.vue"),
    props: false,
    meta: {
      title: "bruinclubs verify your club form",
      metaTags: [
        {
          name: "description",
          content: "Verify your club to display a certified check mark, professional email address, and updated club info",
        },
        {
          property: "og:description",
          content: "Verify your club to display a certified check mark, professional email address, and updated club info",
        },
      ],
    },
  },
  {
    path: "/rankings",
    name: "rankings",
    component: () => import("@/pages/main/Rankings.vue"),
    props: false,
    meta: {
      title: "bruinclubs club rankings",
      metaTags: [
        {
          name: "description",
          content: "A list of every club on campus ranked by popularity",
        },
        {
          property: "og:description",
          content: "A list of every club on campus ranked by popularity",
        },
      ],
    },
  },
  {
    path: "/prizes",
    name: "prizes",
    component: () => import("@/pages/main/Prizes.vue"),
    props: false,
    meta: {
      title: "bruinclubs club prizes",
      metaTags: [
        {
          name: "description",
          content: "Popular clubs can receive prizes for being one of the top clubs on our rankings",
        },
        {
          property: "og:description",
          content: "Popular clubs can receive prizes for being one of the top clubs on our rankings",
        },
      ],
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
